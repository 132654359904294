@import '../../../styles/mixins/global.scss';

.card-section-content-alignment {
    padding: 97px 0 127px 0;
    position: relative;
    @include breakpoint("max-md") {
        padding: 70px 0;
    }

    @include breakpoint("max-sm") {
        padding: 60px 0;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 120px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
            padding: 0 50px;
        }


        .grid-items {
            button {
                color: var(--white-color);
                width: 100%;
                padding: 8px;
                background-color: var(--black-color);
                @include inter-13;
                @include pointer;
                border: none;
                height: 38px;
            }

            p {
                @include normal-16;
                color: var(--black-color);
                line-height: 22px;
                margin: 50px 0 25px 0;

                @include breakpoint("max-md") {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 18px;
                    max-width: 210px;
                }
            }

            h3 {
                @include normal-24;
                color: var(--black-color);
                position: relative;

                @include breakpoint("max-md") {
                    font-size: 16px;
                    line-height: 20px;
                }

                &::before {
                    position: absolute;
                    bottom: -22px;
                    left: 0;
                    content: "";
                    height: 1px;
                    width: 109px;
                    background-color: var(--black-color);
                }
            }
        }
    }
}
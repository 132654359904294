@import "../../styles/mixins/global.scss";

.krankenversicherung-page-banner {
  background-image: url("../../assets/images/Rectangle45.svg");
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  width: 100%;
  background-position: center center;
  height: calc(432px - 0px);

  @include breakpoint("max-md") {
    height: 338px;
  }

  @include breakpoint("max-sm") {
    background-image: url("../../assets/images/Rectangle11.svg");
    background-attachment: inherit;
  }

  .container-md {
    height: 100%;
    display: flex;
    align-items: flex-end;
    max-width: 1290px;
  }

  h1 {
    @include bold-24;
    color: #000;
    max-width: 378px;
    margin: 0 0 41px 0;

    @include breakpoint("max-sm") {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 60px 0;
    }
  }
}

.krankenversicherung-section-all-content-alignment {
  position: relative;
  background-color: #fff;
  .container-sm-5 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }

  .dark-box {
    background: rgba(169, 89, 89, 0.52);
    border-radius: 2px;
    padding: 40px;
    margin: 72px 0 52px 0;

    @include breakpoint("max-md") {
      margin: 0 0 20px 0;
      padding: 40px 85px;
    }

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    h4 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 30px 0;

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0;

      @include breakpoint("max-md") {
        font-size: 10px;
        line-height: 18px;
      }
    }
  }

  .pfle-box-design {
    background: rgba(169, 89, 89, 0.2);
    border-radius: 2px;
    margin: 0 0 60px 0;
    padding: 40px;

    @include breakpoint("max-md") {
      padding: 40px 85px;
    }

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    h4 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 30px 0;

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0;

      @include breakpoint("max-md") {
        font-size: 10px;
        line-height: 18px;
      }
    }
  }

  .kar-box-desgin {
    background: rgba(169, 89, 89, 0.52);
    border-radius: 2px;
    padding: 40px;
    margin: 0 0 52px 0;

    @include breakpoint("max-md") {
      margin: 0 0 20px 0;
    }

    h4 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 30px 0;

      @include breakpoint("max-sm") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0;

      @include breakpoint("max-md") {
        font-size: 10px;
        line-height: 18px;
      }
    }
  }

  .without-background-all-content-style {
    padding: 52px 0;

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    .all-text-style:last-child {
      padding: 0;
    }

    .all-text-style {
      padding: 0 0 30px 0;

      h5 {
        @include normal-16;
        color: var(--black-color);
        font-weight: 600;
        margin: 0;

        @include breakpoint("max-md") {
          margin: 0 0 19px 0;
        }
      }

      p {
        @include normal-16;
        color: var(--black-color);
        margin: 0;

        @include breakpoint("max-sm") {
          font-size: 10px;
          line-height: 18px;
        }
      }
    }
  }

  .light-box {
    background: rgba(169, 89, 89, 0.2);
    border-radius: 2px;
    padding: 40px;
    margin: 0 0 0 0;

    @include breakpoint("max-md") {
      padding: 40px 85px;
    }

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    h4 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 30px 0;

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0;

      @include breakpoint("max-md") {
        font-size: 10px;
        line-height: 18px;
      }
    }
  }
}

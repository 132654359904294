@import '../../../styles/mixins/global.scss';

.herzlich-section-banner {
    background-color: var(--light-background-1);
    padding: 68px 0 62px 0;
    position: relative;
    @include breakpoint("max-md") {
        padding: 40px 0;
    }

    @include breakpoint("max-sm") {
        padding: 40px 0 63px 0;
    }

    h2 {
        @include bold-24;
        color: var(--black-color);
        margin: 0 0 31px 0;
        font-weight: 600;
        max-width: 732px;
        font-size: 40px;
        line-height: 56px;

        @include breakpoint("max-md") {
            font-size: 16px;
            line-height: 32px;
            max-width: 596px;
            margin: 0 auto 19px auto;
        }
    }

    .no-bold{
        font-weight: 400 !important;
    }

    p {
        @include normal-20;
        color: var(--black-color);
        margin: 0 0 20px 0;


        @include breakpoint("max-md") {
            font-size: 13px;
            margin: 0 auto 20px auto;
            max-width: 596px;
            line-height: 16px;
        }

        @include breakpoint("max-sm") {
            font-size: 10px;
            line-height: 18px;
        }

    }

    p:last-child {
        margin: 30px 0 0 0;
        font-weight: 700;

        @include breakpoint("max-md") {
            font-size: 10px;
            max-width: 596px;

            margin: 0 auto 0 auto;
            line-height: 13px;
        }
    }
}
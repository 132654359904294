@import '../../styles/mixins/global.scss';

.header-alignment {
    background-color: var(--white-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    padding: 24px 0;

    .container-lg {
        max-width: 1390px;
    }

    @include breakpoint("max-md") {
        padding: 10px 0;
    }

    @include breakpoint("max-sm") {
        padding: 14px 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    }

    .mobile-toggle {
        display: none;

        @include breakpoint("max-sm") {
            display: block;

            img {
                @include pointer;
            }
        }
    }

    .logo {
        img {
            @include pointer;
            max-width: 257px;

            @include breakpoint("max-md") {
                max-width: 180px;
            }
        }
    }

    .header-content-alignment {
        @include flex-center;
        justify-content: space-between;
    }

    .menu {
        @include breakpoint("max-sm") {
            display: none;
        }

        ul {
            @include flex-center;
            list-style: none;

            li:last-child {
                margin: 0;
            }

            li {
                text-decoration: none;
                @include normal-16;
                @include pointer;
                color: var(--black-color);
                margin-right: 40px;
                position: relative;

                @include breakpoint("max-md") {
                    margin-right: 20px;
                    font-size: 12px;
                    line-height: 15px;
                }

                a {
                    color: var(--black-color);
                }

                &:hover {
                    &::after {
                        transform: scaleX(1);
                        opacity: 1;
                    }
                }

                .active {
                    color: var(--black-color);
                    font-weight: 700;

                    &::before {
                        content: "";
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        bottom: -5px;
                        left: 0;
                        background-color: #A95959;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    bottom: -5px;
                    left: 0;
                    background-color: #A95959;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: left;
                    transform-origin: left;
                    opacity: 0;
                    transition: opacity .3s ease-out, -webkit-transform .3s ease-out;
                    transition: transform .3s ease-out, opacity .3s ease-out;
                    transition: transform .3s ease-out, opacity .3s ease-out, -webkit-transform .3s ease-out;
                }

            }
        }
    }
}

.mobile-header-design {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    background-color: #fff;
    height: 100vh;
    width: calc(100% - 50px);

    .mobile-body-content {
        padding: 0 30px;
        transition: all ease .3s;
        transform: translate(50px);
        width: 100%;
        transition-delay: .3s;
        opacity: 0;

        .mobile-men-style {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                a {
                    @include normal-16;
                    @include pointer;
                    font-weight: 500;

                    color: var(--black-color);

                }

                li {
                    padding: 20px 0;
                    text-decoration: none;
                    color: var(--black-color);
                    @include normal-16;
                    border-bottom: 1px solid #d0d0d0;
                    @include pointer;
                    cursor: pointer;
                    font-weight: 500;

                    a {
                        @include normal-16;
                        @include pointer;
                        font-weight: 500;

                        color: var(--black-color);

                        @include breakpoint("max-sm") {
                            font-size: 14px;
                            line-height: 18px;
                        }

                    }
                }
            }
        }
    }
    .mobile-body-content[data-active=false]{
        transition: .3s !important;
        
    }
    .mobile-body-content[data-active=true]{
        transform: translate(0%);
        opacity: 1;
        transition-delay: none;
    }
    .mobile-head-alignment {
        padding: 30px;
        @include flex-center;
        justify-content: space-between;
        border-bottom: 1px solid #d0d0d0;

        div:first-child {
            img {
                max-width: 150px;
            }
        }

        div {
            i {
                font-size: 25px;
                cursor: pointer;
                color: var(--black-color);
            }
        }
    }
}

.mobile-header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 999;
}

.header-hidden {
    transition-delay: .3s !important;
    transform: translate(100%);
    transition: .4s ease;
}

.header-show {
    transform: translate(0%);
    transition: .4s ease;
}



.top-bar-sticky {
    position: sticky;
    top: 0;
    z-index: 999;
}

.mobile-new-sub-menu {
    width: 100%;
    @include flex-center;
    justify-content: space-between;
}

.sub-menu-alignment-now {
    padding-left: 25px;

    span:first-child {
        padding-top: 16px;
    }

    span {
        display: block;
        padding: 0 0 16px 0;
        font-family: "Montserrat", sans-serif;
        color: var(--black-color);
        font-size: 14px;
        line-height: 16px;
        margin-right: 30px;

        @include breakpoint("max-sm") {
            margin-right: 0;
         
        }
    }
}

.sub-menu-alignment-now-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.sub-menu-alignment-now-show {
    max-height: calc(100vh - 0px);
    overflow: hidden;
    transition: .3s ease-in-out;
}

.animation-time {
    transition: .3s ease-in-out;
}

.sach-top-design {
    background: #EEDEDE;
    height: 0px;
    //60px
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-items: end;
    position: absolute;
    width: 100%;
    // top: 144px;
    transition: height 400ms cubic-bezier(0.52, 0.16, 0.24, 1);   
    z-index: -1;

    @include breakpoint("max-md") {
        padding: 32px 0;
        //32
    }

    @include breakpoint("max-sm") {
        // top: 90px;
        display: none;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .right-content-alignment {
        @include flex-center;
        justify-content: flex-end;

        @include breakpoint("max-sm") {
            overflow-x: auto;
            justify-content: flex-start;
        }

        ::-webkit-scrollbar {
            display: none;
        }

        .active-tab {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                height: 2px;
                width: 100%;
                bottom: -5px;
                left: 0;
                background-color: #A95959;
                transition: transform 0.3s ease-out, opacity 0.3s ease-out;
            }
        }

        a {
            @include normal-14;
            color: var(--black-color);
            display: block;
            position: relative;
            margin-right: 40px;
            @include pointer;
            
            &:hover {
                &::after {
                    transform: scaleX(1);
                    opacity: 1;
                }
            }

            &::after {
                content: "";
                position: absolute;
                height: 2px;
                width: 100%;
                bottom: -5px;
                left: 0;
                background-color: #A95959;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: left;
                transform-origin: left;
                opacity: 0;
                transition: opacity .3s ease-out, -webkit-transform .3s ease-out;
                transition: transform .3s ease-out, opacity .3s ease-out;
                transition: transform .3s ease-out, opacity .3s ease-out, -webkit-transform .3s ease-out;
            }

            @include breakpoint("max-md") {
                font-size: 10px;
                line-height: 12px;
                margin-right: 30px;
            }

            @include breakpoint("max-sm") {
                white-space: nowrap;

            }
        }

        a:last-child {
            margin: 0;
        }
    }
}

.sach-top-design[data-show=true]{
    height: 60px;
    z-index: 999;
}

.anfordern-content-tab {
    background: #EEDEDE;
    height: 0px;
    overflow: hidden;
    display: flex;
    width: 100%;
    position: absolute;
    align-items: center;
    z-index: -1;
    transition: height 400ms cubic-bezier(0.52, 0.16, 0.24, 1);   

    .container-lg{
        width: 100%
    }

    .right-content-alignment {
        @include flex-center;
        justify-content: flex-end;

        .active-tab {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                height: 2px;
                width: 100%;
                bottom: -5px;
                left: 0;
                background-color: #A95959;
                transition: transform 0.3s ease-out, opacity 0.3s ease-out;
            }
        }


        a {
            margin-right: 40px;
            position: relative;
            @include pointer;
            display: block;
            @include normal-14;
            color: var(--black-color);

            &:hover {
                &::after {
                    transform: scaleX(1);
                    opacity: 1;
                }
            }

            &::after {
                content: "";
                position: absolute;
                height: 2px;
                width: 100%;
                bottom: -5px;
                left: 0;
                background-color: #A95959;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: left;
                transform-origin: left;
                opacity: 0;
                transition: opacity .3s ease-out, -webkit-transform .3s ease-out;
                transition: transform .3s ease-out, opacity .3s ease-out;
                transition: transform .3s ease-out, opacity .3s ease-out, -webkit-transform .3s ease-out;
            }

            @include breakpoint("max-md") {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
            }
        }

        a:last-child {
            margin: 0;
        }
    }
}

.anfordern-content-tab[data-show=true]{
    height: 60px;
    z-index: 999;
}
@import '../../../styles/mixins/global.scss';

.referenzen-section-content-alignment {
    padding: 0 0 95px 0;

    @include breakpoint("max-md") {
        padding: 0 0 60px 0;
    }

    @include breakpoint("max-sm") {
        padding: 0 0 80px 0;
    }

    .page-title {
        padding: 0 0 70px 0;

        @include breakpoint("max-md") {
            padding: 0 0 60px 0;
        }

        @include breakpoint("max-sm") {
            padding: 0 0 30px 0;
        }

        h2 {
            @include text-center;
            @include heading-2;
            margin: 0 0 35px 0;
            color: var(--black-color);

            @include breakpoint("max-md") {
                font-size: 20px;
                line-height: 33px;
            }

            @include breakpoint("max-sm") {
                font-size: 16px;
                line-height: 33px;
                margin: 0;
            }
        }

        p {
            @include text-center;
            @include text;
            color: var(--black-color);
            max-width: 1116px;
            margin: 0 auto;

            @include breakpoint("max-md") {
                font-size: 10px;
                line-height: 18px;
                max-width: 568px;
            }

            @include breakpoint("max-sm") {
                font-size: 10px;
                line-height: 18px;
                max-width: 316px;
                margin: 0 auto;
            }
        }
    }

    .all-row-alignment {
        .first-row-content-alignment:last-child {
            padding: 0;
        }
    }

    .first-row-content-alignment {
        @include flex-center;
        justify-content: center;
        padding: 0 0 50px 0;
        gap: 100px;

        div {
            img {
                @include pointer;
            }
        }
    }
}

.slider-about-content-images {
    .slider-image-style {
        display: flex!important;
        justify-content: center!important;


        // img {
        //     margin: 0 auto;
        //     max-width: calc(100% - 20%) !important;

        //     @include breakpoint("max-sm"){
        //         width: 100%;
        //     }
        // }
    }
}

.swipper-container{
    position: relative;
    .arrow-design-slider {
        width: 45px;
        height: 45px;
        @include flex-center;
        justify-content: center;
        @include pointer;
      

        @include breakpoint("max-sm") {
            width: 35px;
            height: 35px;
            font-size: 16px;
        }
    }

    .left-arrow-alignment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -60px;
        z-index: 999;

        @include breakpoint("max-sm") {
            left: 0;
        }
    }

    .right-arrow-alignment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -60px;

        @include breakpoint("max-sm") {
            right: 0;
            z-index: 999;

        }
    }
}

// .slick-track{
//     display: flex !important;
//     justify-content: center !important;
//     align-items: center !important;
// }

// .slick-list{
//     padding: 0 0 !important;
//     margin: 0 50px !important;
//     overflow: hidden;
// }

// .react-multi-carousel-track {
//     li{
//         display: flex!important;
//         justify-content: center;
//         align-items: center;
//     }
// }

// .swiper {
//     width: 100%;
//     padding-top: 50px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding-bottom: 50px;
//   }
  
//   .swiper-slide {
//     background-position: center;
//     background-size: cover;
//   }
  
  .swiper-slide img {
    display: block;
    width: auto;
  }

  .swiper-wrapper{
    align-items: center;
  }

  .swiper-slide{
    display: flex;
    padding: 0 0rem;
    justify-content: start;
    img{
        width: auto;
    }
    @include breakpoint("max-sm"){
        justify-content: center;

        img{
            max-width: 230px;
            margin: 0 auto;
            object-fit: cover;
        }
    }
    
  }

  .swiper-button-next, .swiper-button-prev{
    opacity: 0;
    z-index: -1;    
    pointer-events: none;
  }
@import '../../../styles/mixins/global.scss';

.nicht-section-all-content-alignment {
    .container-sm-5 {
        @include breakpoint("max-md") {
            padding: 0;
        }
    }

    .container-sm-6 {
        @include breakpoint("max-md") {
            padding: 0;
        }
    }

    .sterbev-new-box {
        background: rgba(169, 89, 89, 0.2);
        border-radius: 2px;
        padding: 40px;

        h4 {
            @include bold-16;
            color: var(--black-color);
            margin: 0 0 30px 0;
        }

        p {
            @include normal-14;
            color: var(--black-color);

            @include breakpoint("max-sm") {
                font-size: 10px;
                line-height: 18px;
            }
        }
    }

    .oft-section-content-alignment {

        @include breakpoint("max-md") {
            padding: 0 85px;
        }

        @include breakpoint("max-sm") {
            padding: 0 30px;
        }

        .oft-text {
            padding: 0 0 60px 0;

            @include breakpoint("max-md") {
                padding: 0 0 100px 0;
            }

            @include breakpoint("max-sm") {
                padding: 0 0 40px 0;
            }

            h5 {
                @include bold-16;
                color: var(--black-color);
                margin: 0 0 30px 0;

                @include breakpoint("max-md") {
                    margin: 0 0 19px 0;
                }
            }

            p {
                @include normal-14;
                color: var(--black-color);
                margin: 0 0 20px 0;

                @include breakpoint("max-md") {
                    font-size: 10px;
                    line-height: 18px;
                }
            }

            p:last-child {
                margin: 0;
            }
        }
    }

    .dark-box {
        background: rgba(169, 89, 89, 0.52);
        border-radius: 2px;
        padding: 40px;
        margin: 0 0 60px 0;

        @include breakpoint("max-md") {
            padding: 40px 85px;
        }

        @include breakpoint("max-sm") {
            padding: 40px 30px;
            margin: 0 0 40px 0;
        }

        h4 {
            @include bold-16;
            color: var(--black-color);
            margin: 0 0 30px 0;

            @include breakpoint("max-md") {
                margin: 0 0 19px 0;
            }
        }

        p {
            @include normal-14;
            color: var(--black-color);
            margin: 0;

            @include breakpoint("max-md") {
                font-size: 10px;
                line-height: 18px;
            }
        }
    }

    .light-box {
        background: rgba(169, 89, 89, 0.2);
        border-radius: 2px;
        margin: 0 0 60px 0;
        padding: 40px;

        @include breakpoint("max-md") {
            padding: 40px 85px;
            margin: 0 0 20px 0;
        }

        @include breakpoint("max-sm") {
            padding: 40px 30px;
        }

        h4 {
            @include bold-16;
            color: var(--black-color);
            margin: 0 0 30px 0;

            @include breakpoint("max-md") {
                margin: 0 0 19px 0;
            }
        }

        p {
            @include normal-14;
            color: var(--black-color);
            margin: 0;

            @include breakpoint("max-md") {
                font-size: 10px;
                line-height: 18px;
            }
        }
    }
}
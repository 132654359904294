@import '../../../styles/mixins/global.scss';

.team-all-content-alignment {
    padding: 0 0 157px 0;

    @include breakpoint("max-sm") {
        padding: 0;
    }

    .page-title {
        padding: 0 0 60px 0;

        @include breakpoint("max-md") {
            padding: 0 0 143px 0;
        }

        @include breakpoint("max-sm") {
            padding: 0 0 40px 0;
        }

        h3 {
            @include heading-2;
            color: var(--black-color);
            margin: 0;
            @include text-center;

            @include breakpoint("max-md") {
                font-size: 20px;
                line-height: 33px;
            }

            @include breakpoint("max-sm") {
                font-size: 16px;
                line-height: 33px;
            }
        }
    }

    .first-row {
        display: flex;
        justify-content: center;
        padding: 0 0 80px 0;
        gap: 20px;

        @include breakpoint("max-md") {
            flex-wrap: wrap;
            gap: 87px 20px;
        }

        @include breakpoint("max-sm") {
            flex-wrap: wrap;
            gap: 20px 20px;
            padding: 0 0 20px 0;
        }

        .first-row-items {
            width: 252px;

            @include breakpoint("max-md") {
                width: 168px;
            }

            @include breakpoint("max-sm") {
                width: 100%;
            }
        }
    }

    .all-row-content-alignment {
        .first-row:last-child {
            padding: 0 0 0 0;
        }

        // .first-row:nth-child(2) {
        //     .first-row-items:last-child {
        //         @include breakpoint("max-md") {
        //             display: none;
        //         }
        //     }
        // }
    }

    .team-main-card {
        .team-details {
            padding: 10px 0 0 0;

            h2 {
                margin: 0 0 15px 0;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                color: var(--black-color);

                @include breakpoint("max-md") {
                    font-size: 12px;
                    line-height: 14px;
                }
            }

            .new-text {
                padding: 20px 0 0 0;

                @include breakpoint("max-sm") {
                    padding: 10px 0 0 0;
                }

                p {
                    font-family: 'Raleway', sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                    color: var(--black-color);

                    @include breakpoint("max-md") {
                        font-size: 12px;
                        line-height: 32px;
                    }
                }
            }

            .text-grid-alignment {
                padding: 10px 0 0 0;

                @include breakpoint("max-md") {
                    padding: 0;
                }

                .text-grid {
                    display: grid;
                    grid-template-columns: 45px 1fr;
                    gap: 10px;

                    @include breakpoint("max-md") {
                        grid-template-columns: 35px 1fr;
                    }

                    .text-grid-items {
                        a {
                            display: block;
                            font-family: 'Raleway', sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 20px;
                            @include pointer;
                            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                            color: var(--black-color);

                            @include breakpoint("max-md") {
                                font-size: 10px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }

            h3 {
                position: relative;
                font-weight: 500;
                margin: 0;
                font-size: 12px;
                line-height: 20px;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                font-family: 'Raleway', sans-serif;
                color: var(--black-color);

                @include breakpoint("max-md") {
                    font-size: 10px;
                    line-height: 20px;
                }

                &::before {
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    background-color: var(--black-color);
                    height: 1px;
                    width: 34px;
                    content: "";

                    @include breakpoint("max-md") {
                        bottom: 0;
                    }

                    @include breakpoint("max-sm") {
                        bottom: 1px;
                    }
                }
            }
        }

        .card-image {
            img {
                width: 100%;
                height: 300px;
                object-fit: cover;

                @include breakpoint("max-md") {
                    height: 200px;
                }

                @include breakpoint("max-sm") {
                    height: 375px;
                }
            }
        }
    }
}

.team-section-modal-wrapper {
    position: fixed;
    top: 0;
    background: rgba(150, 150, 150, 0.61);
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;

    .team-modal {
        width: 1070px;
        background-color: #fff;

        @include breakpoint("max-md") {
            width: calc(100% - 100px);
        }

        @include breakpoint("max-sm") {
            width: calc(100% - 0px);
            max-height: calc(100vh - 100px);
            overflow: auto;
        }

        .team-modal-body {
            padding: 15px 62px 40px 62px;

            @include breakpoint("max-md") {
                padding: 15px 34px 40px 34px;
            }

            @include breakpoint("max-sm") {
                padding: 15px 27px 40px 27px;
            }

            .team-modal-grid {
                display: grid;
                grid-template-columns: 455px 1fr;
                gap: 20px;
                font-family: 'Raleway', sans-serif;

                @include breakpoint("max-md") {
                    grid-template-columns: 222px 1fr;
                }

                @include breakpoint("max-md") {
                    grid-template-columns: repeat(1, 1fr);
                }

                .team-modal-grid-items {
                    height: 100%;
                    font-family: 'Raleway', sans-serif;

                    .button-style {
                        button {
                            background-color: transparent;
                            cursor: pointer;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                            border: 1px solid #000;
                            padding: 18px 63px;

                            @include breakpoint("max-md") {
                                padding: 12px 24px;
                            }

                            @include breakpoint("max-sm") {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 24px;
                                padding: 12px 34px;
                            }
                        }
                    }

                    .two-headinf-text-content-alignment {
                        div {
                            padding: 0 0 16px 0;
                        }

                        h2 {
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 14px;
                            margin: 0 0 5px 0;
                            color: #000;
                        }

                        p {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

                            color: #000;

                            @include breakpoint("max-md") {
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 24px;
                            }
                        }
                    }

                    .two-new-content-alignment {
                        display: flex;
                        gap: 40px;
                        padding: 0 0 26px 0;

                        @include breakpoint("max-md") {
                            padding: 0 0 10px 0;
                        }

                        @include breakpoint("max-md") {
                            gap: 20px;
                        }

                        div {
                            min-width: 160px;

                            h6 {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 14px;
                                color: #000;

                                @include breakpoint("max-md") {
                                    font-weight: 700;
                                    font-size: 11px;
                                    line-height: 13px;
                                }
                            }

                            p {
                                font-weight: 500;
                                font-size: 12px;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 32px;
                                color: #000;
                                line-height: 32px;
                            }

                            .space-top {
                                padding: 6px 0 0 0;
                            }

                            a {
                                display: block;
                                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                                color: #000;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 20px;
                                cursor: pointer;

                                @include breakpoint("max-md") {
                                    font-weight: 500;
                                    font-size: 9px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }

                    .two-flex-content-alignment {
                        display: flex;
                        gap: 40px;
                        padding: 0 0 20px 0;

                        @include breakpoint("max-md") {
                            gap: 20px;
                        }

                        div {
                            min-width: 160px;

                            h6 {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 14px;
                                font-family: 'Raleway', sans-serif;

                                @include breakpoint("max-sm") {
                                    font-weight: 700;
                                    font-size: 11px;
                                    line-height: 13px;
                                }
                            }

                            span {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 32px;
                                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                                display: block;
                                color: #000;

                                @include breakpoint("max-md") {
                                    font-weight: 500;
                                    font-size: 9px;
                                    line-height: 32px;
                                }
                            }
                        }
                    }

                    .heading-style {
                        padding: 0 0 46px 0;

                        h1 {
                            font-family: 'Raleway', sans-serif;
                            font-weight: 600;
                            font-size: 32px;
                            line-height: 38px;
                            text-transform: uppercase;

                            color: #000;

                            @include breakpoint("max-md") {
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 23px;
                            }
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;

                        @include breakpoint("max-md") {
                            height: 278px;
                        }

                        @include breakpoint("max-sm") {
                            height: 278px;
                            width: 222px;
                        }
                    }
                }
            }
        }

        .team-modal-header {
            padding: 14px 15px;
            @include flex-center;
            justify-content: flex-end;

            @include breakpoint("max-md") {
                padding: 14px 45px;
            }

            @include breakpoint("max-sm") {
                position: sticky;
                top: 0;
                left: 0;
                background-color: #fff;
                z-index: 999;
            }

            img {
                cursor: pointer;

                @include breakpoint("max-md") {
                    max-width: 30px;
                }
            }
        }
    }
}
@use '../mixins/breakpoint.scss';

// font-family: 'Montserrat', sans-serif;

// font-family: 'Inter', sans-serif;

// font-family: "VisbyCF-Regular";
// font-family: "VisbyCF-Light";

// font-family: "VisbyBold";

@mixin bold-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Montserrat', sans-serif;
}

@mixin text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-family: "VisbyCF-Regular";
}

@mixin normal-24 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Montserrat', sans-serif;
}

@mixin normal-24 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Montserrat', sans-serif;
}

@mixin bold-32 {
    font-weight: 600;
    font-size: 32px;
    line-height: 41px;
    font-family: 'Montserrat', sans-serif;
}

@mixin heading-2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 33px;
    font-family: "VisbyBold";
}

@mixin heading-3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: "VisbyBold";
}

@mixin text-sm {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    font-family: "VisbyBold";
}

@mixin text-sm-normal {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: "VisbyCF-Regular";
}

@mixin inter-13 {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    font-family: 'Inter', sans-serif;
}

@mixin normal-24 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Montserrat', sans-serif;
}

@mixin normal-20 {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    font-family: 'Montserrat', sans-serif;
}

@mixin normal-13 {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    font-family: 'Montserrat', sans-serif;
}

@mixin normal-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Montserrat', sans-serif;
}

@mixin normal-16 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
}

@mixin bold-16 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
}
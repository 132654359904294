@import "../../styles/mixins/global.scss";

.uber-uns-banner {
  background-image: url("../../assets/images/uber-uns-banner-new.png");
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  width: 100%;
  background-position: center;
  height: 580px;
position: relative;

&::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 20%;
  height: 100%;
  background: radial-gradient(510.62% 62.94% at 0% 100%, rgb(255 255 255 / 77%) 30.91%, rgba(255, 255, 255, 0) 78.87%);
  z-index: -1;
}
  @include breakpoint("max-md") {
    height: 480px;
    // background-image: url("../../assets/images/uberunst.svg");
    background-attachment: initial;
    object-fit: contain;
  }
  @include breakpoint("max-sm") {
    height: 333px;
    background-image: url("../../assets/images/uber-uns-banner-new.png");
    background-attachment: initial;
    object-fit: contain;
  }

  .container-md {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  h1 {
    @include bold-24;
    color: #000;
    max-width: 378px;
    margin: 0 0 41px 0;

    @include breakpoint("max-sm") {
      font-weight: 700;
      max-width: 190px;
      margin: 0 0 66px 0;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

@import "../../styles/mixins/global.scss";

.sachversicherungen-banner {
  background-image: url("../../assets/images/new-banner02.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: center center;
  height: calc(432px - 0px);
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: radial-gradient(510.62% 62.94% at 0% 100%, rgb(255 255 255 / 77%) 30.91%, rgba(255, 255, 255, 0) 78.87%);
    z-index: -1;
  }
  @include breakpoint("max-md") {
    height: 340px;
  }

  @include breakpoint("max-sm") {
    background-attachment: initial;
    height: 342px;
    background-image: url("../../assets/images/new-banner02.png");
  }

  .container-md {
    height: 100%;
    display: flex;
    align-items: flex-end;
    max-width: 1290px;
  }

  h1 {
    @include bold-24;
    color: #000;
    max-width: 378px;
    margin: 0 0 41px 0;

    @include breakpoint("max-sm") {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 66px 0;
    }
  }
}

.neubaufinazierrung-banner {
  background: rgba(169, 89, 89, 0.52);
  padding: 40px;
  border-radius: 2px;
  margin: 72px 0 60px 0;

  @include breakpoint("max-md") {
    padding: 40px 85px;
    margin: 0px 0 20px 0;
  }

  @include breakpoint("max-sm") {
    padding: 40px 30px;
  }

  h2 {
    @include normal-16;
    font-weight: 600;
    color: var(--black-color);
    margin: 0 0 30px 0;

    @include breakpoint("max-md") {
      margin: 0 0 19px 0;
    }
  }

  h3 {
    @include normal-14;
    color: var(--black-color);
    font-weight: 600;
    margin: 0 0 30px 0;

    @include breakpoint("max-md") {
      font-size: 10px;
      line-height: 18px;
      margin: 0 0 20px 0;
    }
  }

  p {
    @include normal-14;
    color: var(--black-color);
    margin: 0;

    @include breakpoint("max-md") {
      font-size: 10px;
      line-height: 18px;
    }
  }
}

// .sach-top-design {
//     background: #EEDEDE;
//     height: 0px;
//     //60px
//     overflow: hidden;
//     display: flex;
//     align-items: center;
//     justify-items: end;
//     position: sticky;
//     top: 156px;

//     z-index: 9999;

//     @include breakpoint("max-md") {
//         padding: 32px 0;
//     }

//     @include breakpoint("max-sm") {
//         top: 90px;
//         display: none;
//     }

//     ::-webkit-scrollbar {
//         display: none;
//     }

//     .right-content-alignment {
//         @include flex-center;
//         justify-content: flex-end;

//         @include breakpoint("max-sm") {
//             overflow-x: auto;
//             justify-content: flex-start;
//         }

//         ::-webkit-scrollbar {
//             display: none;
//         }

//         .active-tab {
//             position: relative;

//             &::before {
//                 content: "";
//                 position: absolute;
//                 height: 2px;
//                 width: 100%;
//                 bottom: -5px;
//                 left: 0;
//                 background-color: #A95959;
//                 transition: transform 0.3s ease-out, opacity 0.3s ease-out;
//             }
//         }

//         a {
//             @include normal-14;
//             color: var(--black-color);
//             display: block;
//             position: relative;
//             margin-right: 40px;
//             @include pointer;

//             @include breakpoint("max-md") {
//                 font-size: 10px;
//                 line-height: 12px;
//                 margin-right: 30px;
//             }

//             @include breakpoint("max-sm") {
//                 white-space: nowrap;

//             }
//         }

//         a:last-child {
//             margin: 0;
//         }
//     }
// }
.container-tabs {
  position: relative;
  background-color: #fff;
}

.tab-view-sachversicherungen-remove {
  position: relative;
  background-color: #fff;
  @include breakpoint("max-md") {
    .container-sm-5 {
      padding: 0;
    }
  }
}

.card-link-container {
  margin-top: 20px;
  a {
    @include normal-14;
    padding-bottom: 1px;
    border-bottom: 2px solid #000;
    font-weight: 600;
    color: black;
  }

  .simulate-link {
    @include normal-14;
    padding-bottom: 1px;
    border-bottom: 2px solid #000;
    font-weight: 600;
    color: black;
    cursor: pointer;
  }
}

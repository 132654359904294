@import '../../../styles/mixins/global.scss';

.uber-uns-card-section-alignment {
    padding: 30px 0 97px 0;
    position: relative;
    
    @include breakpoint("max-md") {
        padding: 62px 0 70px 0;
    }

    @include breakpoint("max-sm") {
        padding: 0 0 40px 0;
    }

    .all-card-content-alignment {
        @include breakpoint("max-md") {
            padding: 0 33px;
        }

        @include breakpoint("max-sm") {
            padding: 0 0;
        }

        .card:last-child {
            padding: 0;
        }

        .card {
            padding: 0 0 30px 0;

            @include breakpoint("max-md") {
                padding: 0 0 40px 0;
            }

            .card-grid {
                display: grid;
                grid-template-columns: 140px 1fr;
                gap: 30px;

                @include breakpoint("max-md") {
                    grid-template-columns: 74px 1fr;
                    gap: 30px;
                }

                @include breakpoint("max-sm") {
                    grid-template-columns: 1fr;
                    gap: 0;
                }

                .icon-design {
                    width: 140px;
                    height: 140px;
                    @include flex-center;
                    justify-content: center;
                    @include pointer;

                    @include breakpoint("max-md") {
                        width: 74px;
                        height: 74px;
                    }

                    img {
                        @include breakpoint("max-md") {
                            max-width: 30px;

                        }
                    }
                }

                .text-style {
                    p {
                        @include normal-16;
                        line-height: 20px;
                        color: var(--black-color);

                        @include breakpoint("max-md") {
                            font-size: 10px;
                            line-height: 18px;
                        }
                    }
                }

                .card-grid-items {
                    h4 {
                        @include bold-24;
                        color: var(--black-color);
                        margin: 0 0 25px 0;

                        @include breakpoint("max-sm") {
                            font-size: 16px;
                            line-height: 20px;
                            margin: 0 0 15px 0;
                        }

                    }

                    .top-align {
                        padding: 20px 0 0 0;
                    }

                    .card-text-style {
                        p {
                            @include normal-16;
                            line-height: 20px;
                            color: var(--black-color);
                            margin: 0 0 20px 0;

                            @include breakpoint("max-md") {
                                font-size: 10px;
                                line-height: 18px;
                            }
                        }

                        p:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
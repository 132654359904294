@import '../../../styles/mixins/global.scss';

.aktuelle-section-content-alignment {
    .kfz-box-content-alignment {
        background: rgba(169, 89, 89, 0.2);
        border-radius: 2px;
        margin: 0 0 60px 0;
        padding: 40px 40px 28px 40px;
        position: relative;

        @include breakpoint("max-md") {
            padding: 40px 85px;
        }

        @include breakpoint("max-sm") {
            padding: 40px 30px;
        }

        h3 {
            @include bold-16;
            color: var(--black-color);
            margin: 0 0 30px 0;

            @include breakpoint("max-md") {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
            }
        }

        .card-footer-text-style {
            p {
                @include normal-14;
                color: var(--black-color);

                @include breakpoint("max-sm") {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 18px;
                }

                span {
                    font-weight: 600;
                }
            }
        }

        .child-text-content-alignment {
            padding: 0 0 59px 0;

            @include breakpoint("max-sm") {
                padding: 0 0 20px 0;
            }

            p {
                @include normal-14;
                color: var(--black-color);
                margin: 0 0 16px 0;

                @include breakpoint("max-sm") {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 18px;
                }
            }

            h6 {
                @include normal-14;
                color: var(--black-color);

                @include breakpoint("max-sm") {
                    font-size: 10px;
                    line-height: 18px;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                padding-left: 1.85rem;

                li {
                    @include normal-14;
                    color: var(--black-color);

                    @include breakpoint("max-sm") {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    .three-box-design {
        background: rgba(169, 89, 89, 0.52);
        border-radius: 2px;
        margin: 0 0 60px 0;
        padding: 40px;

        @include breakpoint("max-md") {
            padding: 40px 85px;
            margin: 0 0 20px 0;
        }

        @include breakpoint("max-sm") {
            padding: 40px 30px;
        }

        h4 {
            @include normal-16;
            font-weight: 600;
            color: var(--black-color);
            margin: 0 0 30px 0;

            @include breakpoint("max-md") {
                margin: 0 0 19px 0;
            }
        }

        p {
            @include normal-14;
            color: var(--black-color);
            margin: 0 0 30px 0;

            @include breakpoint("max-md") {
                font-size: 10px;
                line-height: 18px;
                margin: 0 0 20px 0;
            }
        }

        p:last-child {
            margin: 0;
        }
    }

    .light-boxd-design {
        background: #EEDEDE;
        border-radius: 2px;
        padding: 40px;
        margin: 0 0 60px 0;

        @include breakpoint("max-md") {
            padding: 40px 85px;
            margin: 0 0 60px 0;
        }

        @include breakpoint("max-sm") {
            padding: 40px 30px;
            margin: 0 0 20px 0;
        }

        h3 {
            @include normal-16;
            font-weight: 600;
            color: var(--black-color);
            margin: 0 0 30px 0;

            @include breakpoint("max-md") {
                margin: 0 0 19px 0;
            }
        }

        p {
            @include normal-14;
            color: var(--black-color);
            margin: 0 0 30px 0;

            @include breakpoint("max-md") {
                font-size: 10px;
                line-height: 18px;
                margin: 0 0 20px 0;
            }
        }

        p:last-child {
            margin: 0;
        }
    }

    .text-style {
        padding: 0 0 40px 0;

        p {
            @include text-sm-normal;
            line-height: 20px;
            color: var(--black-color);
            margin: 0 auto 20px auto;
            max-width: 692px;

            @include breakpoint("max-md") {
                font-size: 10px;
                max-width: 623px;
                margin: 0 auto 20px auto;
                line-height: 19px;
            }
        }
    }

    .table-center-alignment {
        @include flex-center;
        justify-content: center;
        padding: 0 0 60px 0;

        .table-alignment {
            width: 692px;

            @include breakpoint("max-md") {
                width: 603px;
            }

            @include breakpoint("max-sm") {
                width: 100%;
            }

            table {
                width: 100%;
                border-collapse: collapse;

                tr {
                    th {
                        padding: 10px;
                        @include text-sm;
                        color: var(--black-color);
                    }

                    td {
                        padding: 10px;
                        color: var(--black-color);

                        span {
                            @include text-sm-normal;
                            color: var(--black-color);
                        }
                    }
                }
            }
        }
    }
}

.card-link-container {
    margin-top: 20px;
    a {
      @include normal-14;
      padding-bottom: 1px;
      border-bottom:2px solid #000;
      font-weight: 600;
      color: black;
    }
  }
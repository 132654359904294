@import '../../../styles/mixins/global.scss';

.sponsoring-section-banner {
    background-color: var(--light-background-1);
    padding: 47px 0;

    @include breakpoint("max-sm") {
        padding: 20px 0;
    }

    .owner-container {
        display: flex;
        align-items: center;

        @include breakpoint("max-sm") {
            flex-direction: column-reverse;
        }

        .sponsoring-img-container {

            max-width: 220px;
            overflow: hidden;
            margin-left: 20px;

            img {
                object-fit: cover;
                object-position: center center;
                width: 100%
            }

            @include breakpoint("max-sm") {
                margin-bottom: 20px
            }
        }

        h2 {
            @include heading-3;
            color: var(--black-color);
            margin: 0 0 25px 0;
        }

        p {
            @include normal-16;
            color: var(--black-color);
            margin: 0 0 20px 0;
            line-height: 22px;
        }

        p:last-child {
            margin: 0;
        }
    }

}
// @use  '';
@use "../mixins/breakpoint.scss" as bp;
$bp: (
  base: 0px,
  sm: 540px,
  md: 992px,
  lg: 1280px,
);

@import '../mixins/breakpoint';


.container-lg {
  max-width: calc(1294px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-md {
  max-width: calc(1026px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-xl {
  max-width: calc(1213px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-xl-2 {
  max-width: calc(1138px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-sm {
  max-width: calc(947px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-sm-1 {
  max-width: calc(987px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-2xl {
  max-width: calc(1340px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-3xl {
  max-width: calc(1227px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-sm-3 {
  max-width: calc(1114px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-sm-4 {
  max-width: calc(1148px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-sm-5 {
  max-width: calc(883px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}

.container-sm-6 {
  max-width: calc(807px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint("max-sm") {
    padding: 0 30px;
  }
}
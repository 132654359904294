@import "../../styles/mixins/global.scss";

.Vorsorge-page-banner {
  background-image: url("../../assets/images/Rectangle43.svg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: center center;
  height: calc(432px - 0px);

  @include breakpoint("max-md") {
    background-image: url("../../assets/images/Rectangle443.svg");
    height: 448px;
    object-fit: contain;
    width: 127%;
  }

  @include breakpoint("max-sm") {
    // background-image: url("../../assets/images/Rectangle666.svg");
    background-attachment: initial;
  }

  .container-md {
    height: 100%;
    display: flex;
    align-items: flex-end;
    max-width: 1290px;
  }

  h1 {
    @include bold-24;
    color: #000;
    max-width: 378px;
    margin: 0 0 41px 0;

    @include breakpoint("max-sm") {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 66px 0;
    }
  }
}

.kfw-all-content-alignment {
  position: relative;
  background-color: #fff;
  .container-sm-5 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }

  .container-sm-6 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }

  .was-ist-eine-content {
    padding: 0 0 40px 0;

    @include breakpoint("max-md") {
      padding: 0 85px 60px 85px;
    }

    @include breakpoint("max-sm") {
      padding: 0 30px 60px 30px;
    }

    h5 {
      @include normal-16;
      font-weight: 600;
      color: var(--black-color);
      word-break: break-all;
      margin: 0 0 30px 0;

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p:last-child {
      margin: 0;
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0 0 20px 0;

      @include breakpoint("max-md") {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
      }
    }
  }

  .light-box {
    background: rgba(169, 89, 89, 0.52);
    border-radius: 2px;
    padding: 40px;
    margin: 72px 0 40px 0;

    @include breakpoint("max-md") {
      margin: 0 0 60px 0;
      padding: 40px 85px;
    }

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    h1 {
      @include normal-16;
      color: var(--black-color);
      font-weight: 600;
      margin: 0 0 30px 0;

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      line-height: 19px;
      color: var(--black-color);

      @include breakpoint("max-md") {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
      }
    }
  }
}

.kfw-forderungen-content-alignment {
  .container-sm-5 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }

  .container-sm-6 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }

  .enimalige-content-box {
    background: rgba(169, 89, 89, 0.2);
    border-radius: 2px;
    margin: 0 0 60px 0;
    padding: 40px;

    @include breakpoint("max-md") {
      padding: 40px 85px;
    }

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    h2 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 30px 0;

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      color: var(--black-color);

      @include breakpoint("max-md") {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
      }
    }
  }

  .welche-text-style {
    padding: 0 0 60px 0;

    @include breakpoint("max-md") {
      padding: 0 85px 60px 85px;
    }

    h5 {
      @include heading-2;
      color: var(--black-color);
      margin: 0 0 20px 0;

      @include breakpoint("max-md") {
        font-weight: 600;
        margin: 0 0 19px 0;
        font-size: 20px;
        line-height: 41px;
      }
    }

    h6 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 20px 0;
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0 0 20px 0;

      @include breakpoint("max-md") {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
      }
    }

    p:last-child {
      margin: 0;
    }
  }

  .light-box {
    background: rgba(169, 89, 89, 0.52);
    border-radius: 2px;
    padding: 40px;
    margin: 0 0 60px 0;

    @include breakpoint("max-md") {
      padding: 40px 85px;
    }

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    h4 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 30px 0;

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0 0 20px 0;

      @include breakpoint("max-md") {
        font-size: 10px;
        line-height: 18px;
        font-weight: 400;
      }
    }

    p:last-child {
      margin: 0;
    }
  }

  .dark-box {
    background: rgba(169, 89, 89, 0.2);
    border-radius: 2px;
    padding: 40px;
    margin: 0 0 40px 0;

    @include breakpoint("max-md") {
      padding: 40px 85px;
      margin: 0 0 20px 0;
    }

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    h5 {
      @include bold-16;
      margin: 0 0 30px 0;
      color: var(--black-color);

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0;

      @include breakpoint("max-md") {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
      }
    }
  }
}

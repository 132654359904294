@import "../../styles/mixins/global.scss";

.personenversicherungen-page-banner {
  background-image: url("../../assets/images/new-banner03.png");
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: center center;
  height: calc(432px - 0px);
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: radial-gradient(510.62% 62.94% at 0% 100%, rgb(255 255 255 / 77%) 30.91%, rgba(255, 255, 255, 0) 78.87%);
    z-index: -1;
  }
  @include breakpoint("max-md") {
    height: 338px;
  }

  @include breakpoint("max-sm") {
    background-image: url("../../assets/images/new-banner03.png");
    background-attachment: initial;
  }

  .container-md {
    height: 100%;
    display: flex;
    align-items: flex-end;
    max-width: 1290px;
  }

  h1 {
    @include bold-24;
    color: #000;
    max-width: 378px;
    margin: 0 0 41px 0;

    @include breakpoint("max-sm") {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 60px 0;
    }
  }
}

.altbaus-box {
  background: rgba(169, 89, 89, 0.52);
  border-radius: 2px;
  padding: 40px;
  margin: 72px 0 60px 0;

  @include breakpoint("max-md") {
    margin: 0 0 60px 0;
    padding: 40px 85px;
  }

  @include breakpoint("max-sm") {
    padding: 40px 30px;
  }

  h2 {
    @include bold-16;
    color: var(--black-color);
    margin: 0 0 30px 0;

    @include breakpoint("max-md") {
      margin: 0 0 19px 0;
    }
  }

  p {
    @include normal-14;
    color: var(--black-color);

    @include breakpoint("max-md") {
      font-size: 10px;
      line-height: 18px;
    }
  }
}

.warum-eine-altbaus-content-alignment {
  padding: 0 0 60px 0;

  @include breakpoint("max-md") {
    padding: 0 0 60px 0;
  }

  .container-sm-6 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }

  .text-all-alignment {
    @include breakpoint("max-md") {
      padding: 0 85px;
    }

    @include breakpoint("max-sm") {
      padding: 0 30px;
    }

    .text-style:last-child {
      padding: 0;
    }

    .text-style {
      padding: 0 0 30px 0;

      @include breakpoint("max-md") {
        padding: 0 0 100px 0;
      }

      h4 {
        @include bold-16;
        color: var(--black-color);
        margin: 0 0 30px 0;
        word-break: break-all;

        @include breakpoint("max-md") {
          margin: 0 0 19px 0;
        }
      }

      p {
        @include normal-14;
        color: var(--black-color);
        margin: 0;

        @include breakpoint("max-md") {
          font-size: 10px;
          line-height: 18px;
        }
      }
    }
  }
}

.ins-container-space-remove {
  .container-sm-5 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }
}

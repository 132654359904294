@import '../../../styles/mixins/global.scss';

.about-sport-section-content-alignment {
    padding: 53px 0 46px 0;

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        align-items: center;

        @include breakpoint("max-sm") {
            display:flex;
            flex-direction: column-reverse;
            
        }

        .ccontent-right-alignment {
            display: flex;
            justify-content: flex-end;
        }

        .text-align-about-content {
            padding-right: 20px;
            border-right: 1px solid #000000;
            padding-top: 40px;
            padding-bottom: 40px;

            @include breakpoint("max-sm") {
                border-right: none;
                border-top: 1px solid #000000;
            }

            p {
                @include normal-16;
                color: #000;
                float: right;
                max-width: 480px;
                text-align: end;

                @include breakpoint("max-sm") {
                    text-align: center;
                }
            }
        }

        .grid-items {
            img {
                width: 100%;
                height: 379px;
                object-fit: cover;
            }
        }
    }

    .page-title {

        h4 {
            @include heading-3;
            color: var(--black-color);
            margin: 0 0 45px 0;
        }

        p {
            @include normal-16;
            color: var(--black-color);
            line-height: 22px;
            margin: 0;
        }
    }
}
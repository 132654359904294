@import '../../styles/mixins/global.scss';

.kontakt-all-content-alignment {
    padding: 60px 0 100px 0;

    .container-3xl {
        @include breakpoint("max-sm") {
            max-width: 100%;
            padding: 20px 10px 20px 10px;
            @include flex-center;
            justify-content: center;
        }
    }

    @include breakpoint("max-md") {
        padding: 40px 0 60px 0;
        @include flex-center;
        justify-content: center;
    }

    @include breakpoint("max-sm") {
        max-width: 500px;
        padding: 20px 10px 20px 10px;
        @include flex-center;
        justify-content: center;
    }

    .animation-time {
        transition: .3s ease-in-out;
    }

    .rotate-icon {
        transform: rotate(180deg);
    }

    .dropdown-relative-div {
        position: relative;

        .dropdown-hidden {
            max-height: 0px;
            overflow: hidden;
            transition: .3s ease-in-out;
        }

        .dropdown-show {
            max-height: 300px;
            overflow: auto;
            transition: .3s ease-in-out;
        }

        .dropdown-content-alignment {
            position: absolute;
            top: 38px;
            left: 0;
            width: 100%;
            z-index: 9999;

            .dropdown-content-alignment-design {
                background: #F2E6E6;
                border: 1px solid #FFFFFF;
                padding: 10px 18px;

                span:last-child {
                    padding: 0;
                }

                span {
                    display: block;
                    color: rgba(21, 10, 1, 0.5);
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 25px;
                    font-family: 'Inter', sans-serif;
                    cursor: pointer;
                }
            }
        }
    }

    .name-label {

        label {
            display: block;
            color: var(--black-color);
            font-weight: 500;
            font-size: 11px;
            padding: 0 0 10px 0;
            line-height: 22px;
            font-family: 'Montserrat', sans-serif;

        }
    }

    .two-col-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        padding: 0 0 15px 0;

        @include breakpoint("max-sm") {
            gap: 15px;
        }


    }

    .input-bottom-content-alignment {
        margin: 0 0 15px 0;
    }

    .anrede-label {
        padding: 0 0 15px 0;

        label {
            display: block;
            color: var(--black-color);
            font-weight: 500;
            font-size: 11px;
            padding: 0 0 5px 0;
            line-height: 22px;
            font-family: 'Montserrat', sans-serif;

        }
    }

    .last-text {
        h5 {
            @include bold-16;
            color: var(--black-color);
            margin: 0 0 10px 0;
            font-weight: 700;
        }

        .text-dec {
            text-decoration-line: underline;
        }

        span {
            display: block;
            font-weight: 400;
            font-size: 11px;
            line-height: 17px;
            columns: var(--black-color);
            font-family: 'Montserrat', sans-serif;

            @include pointer;
        }
    }

    .map-image {
        padding: 0 0 43px 0;

        @include breakpoint("max-md") {
            padding: 0 0 20px 0;
        }

        img {
            width: 100%;
            height: 316px;
            object-fit: cover;

            @include breakpoint("max-md") {
                height: 260px;
            }
        }
    }

    .send-button {
        button {
            background-color: var(--black-color);
            color: var(--white-color);
            @include pointer;
            border: none;
            height: 38px;
            min-width: 115px;
            padding: 7px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
            font-size: 11px;
            line-height: 22px;

        }
    }

    .input {
        position: relative;

        .icon-alignment {
            position: absolute;
            right: 18px;
            top: 50%;
            transform: translateY(-50%);
            right: 18px;
            @include pointer;
            @include flex-center;
        }

        input {
            width: 100%;
            background: #F9FAFA;
            border: 1px solid #CCCCCC;
            height: 38px;
            font-weight: 500;
            font-size: 11px;
            line-height: 22px;
            color: var(--black-color);
            font-family: 'Inter', sans-serif;
            padding: 0 20px;
        }

        textarea {
            width: 100%;
            background: #F9FAFA;
            border: 1px solid #CCCCCC;
            height: 80px;
            font-weight: 500;
            font-size: 11px;
            line-height: 22px;
            color: var(--black-color);
            font-family: 'Inter', sans-serif;
            padding: 8px 20px;

            &:focus {
                outline: none;
            }
        }

        ::placeholder {
            font-weight: 500;
            font-size: 11px;
            line-height: 22px;
            color: rgba(21, 10, 1, 0.5);
            font-family: 'Inter', sans-serif;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 660px;
        gap: 137px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
            width: 546px;
        }

        @include breakpoint("max-sm") {
            width: 100%;
        }

        .grid-items:nth-child(1) {
            @include breakpoint("max-md") {
                width: 430px;
            }

            @include breakpoint("max-sm") {
                width: 100%;
            }
        }

        .grid-items {
            .box-style {
                padding: 0 0 20px 0;

                h1 {
                    @include bold-16;
                    color: var(--black-color);
                    font-weight: 700;
                    margin: 0 0 10px 0;
                }

                p {
                    @include normal-13;
                    font-size: 11px;
                    max-width: 371px;
                    line-height: 20px;
                    color: var(--black-color);
                    margin: 0;

                    @include breakpoint("max-md") {
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}

.einwiligung-all-contnet-alignment {
    padding: 0 0 15px 0;
    p {
        font-size: 14px;
        margin: 0 0 8px 0;
        font-weight: 400;
    }

    .checkbox-label-alignment {
        display: flex;
        align-items: center;
        gap: 6px;

        label {
            font-size: 11px;
            line-height: 16px;
            font-weight: 300;
            color: var(--black-color);
            display: block;
        }

        input[type="checkbox"] {
            width: 14px;
            accent-color: #000;
            height: 14px;
        }
    }
}
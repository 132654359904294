@import "../../styles/mixins/global.scss";

.investment-page-banner {
  background-image: url("../../assets/images/Rectangle44.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(432px - 0px);

  @include breakpoint("max-md") {
    background-image: url("../../assets/images/Rectangle776.svg");
    height: 383px;
  }

  @include breakpoint("max-sm") {
    background-image: url("../../assets/images/Rectangle777.svg");
    background-attachment: inherit;
  }

  .container-md {
    height: 100%;
    display: flex;
    align-items: flex-end;
    max-width: 1290px;
  }

  h1 {
    @include bold-24;
    color: #000;
    max-width: 378px;
    margin: 0 0 41px 0;

    @include breakpoint("max-sm") {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 66px 0;
    }
  }
}

.investment-all-content-alignment {
  position: relative;
  background-color: #fff;
  .container-sm-5 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }

  .container-sm-6 {
    @include breakpoint("max-md") {
      padding: 0;
    }
  }

  .finanzierung-all-content-box-style {
    background: rgba(169, 89, 89, 0.2);
    padding: 40px;
    border-radius: 2px;
    margin: 0 0 60px 0;

    h6 {
      @include bold-24;
      color: var(--black-color);
      margin: 0 0 27px 0;
    }

    .finanzierung-all-child-content-alignment {
      .finanzierung-text-style:last-child {
        padding: 0;
      }

      .finanzierung-text-style {
        padding: 0 0 60px 0;

        h3 {
          @include normal-14;
          color: var(--black-color);
          font-weight: 600;
          margin: 0 0 20px 0;
        }

        p {
          @include normal-14;
          color: var(--black-color);
          margin: 0;
        }
      }
    }
  }

  .dark-box {
    padding: 40px;
    background: rgba(169, 89, 89, 0.52);
    border-radius: 2px;
    margin: 72px 0;

    @include breakpoint("max-md") {
      margin: 0 0 60px 0;
      padding: 40px 85px;
    }

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    .investment-title-text-style {
      h6 {
        @include bold-24;
        color: var(--black-color);
        margin: 0 0 30px 0;
      }
    }

    .all-child-investment-content-alignment {
      .investment-content-text:last-child {
        padding: 0;
      }

      .investment-content-text {
        padding: 0 0 60px 0;

        h1 {
          @include normal-14;
          font-weight: 600;
          color: var(--black-color);
          margin: 0 0 20px 0;
        }

        p {
          @include normal-14;
          color: var(--black-color);
          margin: 0;
        }
      }
    }

    h4 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 30px 0;

      @include breakpoint("max-md") {
        margin: 0 0 19px 0;
      }
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0;

      @include breakpoint("max-md") {
        font-weight: 400;
        font-size: 10px;
        line-height: 19px;
      }
    }
  }

  .all-text-content-alignment {
    padding: 0 0 60px 0;

    @include breakpoint("max-md") {
      padding: 0 85px 100px 85px;
    }

    .text-style:last-child {
      padding: 0;
    }

    .text-style {
      padding: 0 0 20px 0;

      @include breakpoint("max-md") {
        padding: 0 0 139px 0;
      }

      h5 {
        @include bold-16;
        color: var(--black-color);
        margin: 0 0 20px 0;
      }

      p {
        @include normal-14;
        color: var(--black-color);
        margin: 0 0 20px 0;

        @include breakpoint("max-md") {
          font-weight: 400;
          font-size: 10px;
          line-height: 19px;
        }
      }

      p:last-child {
        margin: 0;
      }
    }
  }

  .heading-sub-style {
    padding: 0 0 20px 0;

    @include breakpoint("max-md") {
      padding: 0 85px 20px 85px;
    }

    h2 {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 32px;
      line-height: 41px;
      color: var(--black-color);

      @include breakpoint("max-md") {
        font-weight: 600;
        margin: 0 0 10px 0;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .first-text-style {
    padding: 0 0 25px 0;

    @include breakpoint("max-md") {
      padding: 0 85px 100px 85px;
    }

    h5 {
      @include bold-16;
      color: var(--black-color);
      margin: 0 0 20px 0;
    }

    p {
      @include normal-14;
      color: var(--black-color);
      margin: 0 0 20px 0;

      @include breakpoint("max-md") {
        font-weight: 400;
        font-size: 10px;
        line-height: 19px;
      }
    }

    p:last-child {
      margin: 0;
    }
  }
}

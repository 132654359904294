@import '../../../styles/mixins/global.scss';

.home-page-hero-banner {
    background-image: url("../../../assets/images/home-page-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 282px 0 50px 0;
    background-position: top;
    position: relative;
    &::before {
        position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: radial-gradient(510.62% 62.94% at 0% 100%, rgba(255, 255, 255, 0.77) 30.91%, rgba(255, 255, 255, 0) 78.87%);
    z-index: -1;
    }

    .container-md {
        max-width: 1180px
    }
    @include breakpoint("max-sm") {
        padding: 160px 0 15px 0;
        background-image: url("../../../assets/images/home-page-banner.jpg");
        background-attachment: inherit;
    }

    .hero-text-alignment {
        h1 {
            max-width: 450px;
            color: var(--black-color);
            @include bold-24;

            @include breakpoint("max-md") {
                font-size: 16px;
                line-height: 28px;
                max-width: 208px;
            }
        }
    }
}
@import '../../styles/mixins/global.scss';

.about-banner {
    background-image: url("../../assets/images/about-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 430px;
    background-position: center center;

    @include breakpoint("max-sm") {
        background-image: url("../../assets/images/about-banner-movile.png");
        background-attachment: inherit;
    }

    .container-md {
        height: 100%;
        display: flex;
        align-items: flex-end;
        max-width: 1266px
    }

    h1 {
        @include bold-24;
        color: #000;
        max-width: 378px;
        margin: 0 0 41px 0;
    }
}

.about-page-first-content-alignment {
    padding: 50px 0 231px 0;

    h1 {
        max-width: 338px;
        @include heading-3;
        color: var(--black-color);
    }
}

.soziales-engagement-content-alignment {
    padding: 75px 0 57px 0;

    h2 {
        @include heading-3;
        color: var(--black-color);
        margin: 0 0 20px 0;
    }

    p {
        @include normal-20;
        line-height: 28px;
        color: var(--black-color);
        margin: 0;
    }
}
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif, "Inter", sans-serif, "VisbyCF-Regular";
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

p:last-child {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

input:focus {
  outline: none;
}

button:focus {
  outline: none;
}

@import '../../styles/mixins/global.scss';

.impressum-all-content-alignment {
    padding: 100px 0;

    @include breakpoint("max-sm") {
        padding: 40px 0;
    }
    .main-title {
        h1 {
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
            margin: 0 0 20px 0;
            font-weight: 600;
            max-width: 732px;
            font-size: 40px;
            line-height: 56px;
        }
    }
    .new-link-text {
        padding: 10px 0 0 0;
        p {
            font-weight: 500;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
            line-height: 20px;
            margin: 0 0 5px 0;
            a {
                font-weight: 500;
                color: blue;
            }
        }
    }
    .dis-text {
        padding: 20px 0 0 0;
        h4 {
            font-weight: 600;
            font-size: 22px;
            margin: 0 0 12px 0;
            line-height: 30px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
        }

        .new-sub {
            padding: 0 0 16px 0;
            h5 {
                font-weight: 600;
                font-size: 16px;
                margin: 0 0 8px 0;
                line-height: 22px;
                margin: 0 0 10px 0;
                font-family: "Montserrat", sans-serif;
                color: var(--black-color);
            }
            p:last-child {
                margin: 0;
            }
            p {
                font-weight: 400;
                font-size: 14px;
                font-family: "Montserrat", sans-serif;
                color: var(--black-color);
                line-height: 24px;
                margin: 0 0 16px 0;
                a {
                    color: var(--black-color);
    
                }
            }
        }
    }
    .three-text-style {
        padding: 20px 0 0 0;
        h4 {
            font-weight: 600;
            font-size: 22px;
            margin: 0 0 12px 0;
            line-height: 30px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
        }
        p:last-child {
            margin: 0;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
            line-height: 24px;
            margin: 0 0 16px 0;
            a {
                color: var(--black-color);

            }
        }
    }
    .sec-text {
        p {
            font-weight: 400;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
            line-height: 20px;
            margin: 0 0 5px 0;
            span {
                font-weight: 600;
            }
        }
    }
    .vertreten-text {
        padding: 0 0 20px 0;
        h2 {
            font-weight: 600;
            font-size: 18px;
            margin: 0 0 8px 0;
            line-height: 26px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
        }
        p {
            font-weight: 400;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
            line-height: 20px;
        }

    }
    .link-tag-text {
        padding: 20px 0;

        a:last-child {
            padding: 0;
        }

        a {
            font-weight: 400;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
            line-height: 20px;
            display: block;
            cursor: pointer;
            padding: 0 0 4px 0;
        }
    }
    .sub-title-alignment {
        p {
            font-weight: 400;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            color: var(--black-color);
            line-height: 20px;
        }
    }
}
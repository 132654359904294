@import '../../../styles/mixins/global.scss';

.unser-were-banner {
    background-color: #A95959;
    padding: 94px 0 114px 0;

    @include breakpoint("max-md") {
        padding: 37px 0;
    }

    @include breakpoint("max-sm") {
        padding: 34px 0;
    }

    .page-title {
        padding: 0 0 96px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 40px 0;
        }

        h3 {
            @include heading-2;
            color: var(--white-color);
            @include text-center;

            @include breakpoint("max-sm") {
                font-size: 24px;
                line-height: 33px;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
            padding: 0 63px;
        }

        .grid-items:nth-child(2) {
            p {
                max-width: 227px;
            }
        }

        .grid-items:nth-child(4) {
            p {
                max-width: 227px;
            }
        }

        .grid-items:nth-child(3) {
            p {
                max-width: 250px;
            }
        }

        .grid-items {
            .icon-alignment {
                padding: 0 0 25px 0;
            }

            h4 {
                @include normal-24;
                color: var(--white-color);
                text-transform: uppercase;
                margin: 0 0 25px 0;

                @include breakpoint("max-md") {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .child-width {
                max-width: 222px;
            }

            p {
                @include normal-14;
                color: var(--white-color);
                margin: 0;
                line-height: 22px;

                @include breakpoint("max-md") {
                    font-size: 10px;
                    line-height: 18px;
                }
            }
        }
    }

}
@import '../../../styles/mixins/global.scss';

.topbar {
    background-color: var(--light-background);
    height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    transition: height 400ms cubic-bezier(0.52, 0.16, 0.24, 1);   
    align-items: center;

    .container-lg{
        margin: 0;
        margin-right: 65px;

        @include breakpoint("max-md") {
            margin: 0;
        }
    
        @include breakpoint("max-sm") {
            margin: 0;
        }
    }
    @include breakpoint("max-md") {
        height: 32px;
    }

    @include breakpoint("max-sm") {
        height: 32px;
    }

    .content-right-alignment {
        @include flex-center;
        justify-content: flex-end;
    }

    .insta {
        padding-right: 15px;
        @include flex-center;

        @include breakpoint("max-sm") {
            padding-right: 10px;
        }

        img {
            @include pointer;

            @include breakpoint("max-sm") {
                max-width: 14px;
            }
        }
    }

    .linkdin {
        padding-right: 38px;
        @include flex-center;


        @include breakpoint("max-md") {
            padding-right: 20px;
        }

        @include breakpoint("max-sm") {
            padding-right: 10px;
        }

        img {
            @include pointer;

            @include breakpoint("max-sm") {
                max-width: 14px;
            }
        }
    }
    .facebook {
        // Add your specific styles for .facebook here.
        // For example:
        padding-right: 13px;
    
        
        img {
            @include pointer;

            @include breakpoint("max-sm") {
                max-width: 14px;
            }
        }
    }

    .call {
        a {
            display: block;
            @include normal-13;
            color: var(--black-color);
            @include pointer;

            @include breakpoint("max-md") {
                font-size: 10px;
                line-height: 19px;
            }
        }
    }
}

.topbar[data-show=false]{
    height: 0;
}
@import '../../../styles/mixins/global.scss';

.wor-sind-section-banner {
    background-color: var(--light-background-1);
    padding: 60px 0;
    position: relative;
    @include breakpoint("max-md") {
        padding: 40px 0 50px 0;
    }

    @include breakpoint("max-sm") {
        padding: 40px 0 60px 0;
    }

    h2 {
        @include bold-24;
        color: var(--black-color);
        margin: 0 0 20px 0;

        @include breakpoint("max-md") {
            max-width: 587px;
            font-size: 16px;
            margin: 0 auto 20px auto;
            line-height: 32px;
        }
    }

    p {
        @include normal-20;
        line-height: 30px;
        color: var(--black-color);
        margin: 0 0 20px 0;

        @include breakpoint("max-md") {
            font-size: 13px;
            line-height: 16px;
            max-width: 587px;
            margin: 0 auto 20px auto !important;
        }

        @include breakpoint("max-sm") {
            font-size: 10px;
            line-height: 16px;
        }
    }

    p:last-child {
        font-weight: 500;
        margin: 0;

        @include breakpoint("max-sm") {
            margin: 0 !important;
        }
    }

    span {
        font-weight: 100;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-style: italic;

        @include breakpoint("max-md") {
            max-width: 587px;
            display: block;
            margin: 0 auto;
        }
    }
}
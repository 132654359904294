@import '../../styles/mixins/global.scss';

.footer-design {
    background-color: var(--black-color);
    padding: 76px 0 90px 0;

    @include breakpoint("max-md") {
        padding: 30px 0 86px 0;
    }

    @include breakpoint("max-sm") {
        padding: 80px 20px 100px 20px;
    }

    .footer-logo {
        padding: 0 0 28px 0;
        
        @include breakpoint("max-sm") {
            padding: 0 0 30px 0;
        }

        img {
            @include pointer;
            max-width: 220px;

            @include breakpoint("max-sm") {
                max-width: 180px;
            }
        }
    }

    .social-media {
        @include breakpoint("max-md") {
            display: none;
        }
    }

    .footer-details-alignment {
        display: flex;
        justify-content: space-between;

        @include breakpoint("max-md") {
            justify-content: inherit;
        }

        @include breakpoint("max-sm") {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }

        .footer-menu {
            @include breakpoint("max-sm") {
                order: 1;
            }
        }

        .infor-text {
            @include breakpoint("max-sm") {
                order: 2;
            }

            @include breakpoint("max-sm") {
                padding-top: 30px;
                padding-bottom: 32px;
            }
        }

        .icon-alignment {
            @include flex-center;

            img {
                margin-right: 20px;
                @include pointer;
            }

            img:last-child {
                margin: 0;
            }
        }

        .footer-child-menu-alignment {
            display: flex;
            gap: 80px;

            @include breakpoint("max-sm") {
                display: block;
            }

            div+div {
                @include breakpoint("max-sm") {
                    padding: 30px 0 0 0;
                }
            }

            a {
                display: block;
                @include normal-16;
                line-height: 30px;
                color: var(--white-color);


                @include breakpoint("max-md") {
                    font-size: 10px;
                    line-height: 30px;
                }
            }
        }

        .infor-text {
            p {
                @include normal-16;
                color: var(--white-color);
                margin: 0 0 10px 0;
                max-width: 260px;

                @include breakpoint("max-md") {
                    font-size: 11px;
                    line-height: 16px;
                }
            }

            span,
            a {
                display: block;
                @include normal-16;
                line-height: 22px;
                color: var(--white-color);
                font-weight: 700;
                @include pointer;

                @include breakpoint("max-md") {
                    font-size: 11px;
                    line-height: 18px;
                }
            }
        }

        h4 {
            @include normal-16;
            font-weight: 700;
            margin: 0 0 28px 0;
            color: var(--white-color);

            @include breakpoint("max-sm") {
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 20px 0;
            }
        }
    }
}

.mobile-footer-content-alignment {
    display: none;

    @include breakpoint("max-md") {
        display: block;
    }

    .social-media-mobile {}

    .icon-alignment {
        @include flex-center;

        img {
            margin-right: 20px;
            @include pointer;
        }

        img:last-child {
            margin: 0;
        }
    }

    h4 {
        @include normal-16;
        font-weight: 700;
        margin: 0 0 28px 0;
        color: var(--white-color);

        @include breakpoint("max-sm") {
            margin: 0 0 20px 0;
            font-size: 14px;
            line-height: 20px;
        }
    }
}
@import '../../styles/mixins/global.scss';

.datenschutz-page{
    padding: 5rem 8rem 5rem;
    @include normal-14;

    h1, h2, h3{
        padding: 1rem 0;
    }

    p{
        padding: 0.25rem 0;
    }

    a{
        color: #a95959;
    }

    @include breakpoint("max-md"){
        padding: 4rem 3rem;

        h1, h2, h3{
            padding: 0.75rem 0;
        }

        p{
            padding: 0.15rem 0;
        }
    }

    @include breakpoint("max-sm"){
        padding: 1rem 1.75rem 2rem;
        h2{
            font-size: 1.15rem;
        }
        h1, h2, h3{
            padding: 0.75rem 0;
        }

        p{
            padding: 0.15rem 0;
        }
        ul{
            margin-block-start: 0em;
            margin-block-end: 0em;
            padding-inline-start: 1rem;
            list-style: outside;
        }

        li{
            padding: 0.25rem 0;
        }
    }
}
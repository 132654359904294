@font-face {
    font-family: "VisbyCF-Regular";
    src: url("../../assets/fonts/VisbyCF-Regular.otf");
}

@font-face {
    font-family: "VisbyCF-Medium";
    src: url("../../assets/fonts/VisbyCF-Medium.otf");
}

@font-face {
    font-family: "VisbyCF-Light";
    src: url("../../assets/fonts/VisbyCF-LightOblique.otf");
}

@font-face {
    font-family: "VisbyBold";
    src: url("../../assets/fonts/VisbyBold.otf");
}

/* raleway-300 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: url('../../assets/fonts/raleway-v28-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/raleway-v28-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/raleway-v28-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/raleway-v28-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-regular - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/raleway-v28-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/raleway-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/raleway-v28-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-500 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/fonts/raleway-v28-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/raleway-v28-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/raleway-v28-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/raleway-v28-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-600 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/raleway-v28-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/raleway-v28-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/raleway-v28-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/raleway-v28-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-700 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/raleway-v28-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/raleway-v28-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/raleway-v28-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/raleway-v28-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/raleway-v28-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
  }

  /* montserrat-100 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url('../../assets/fonts/montserrat-v25-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/montserrat-v25-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/montserrat-v25-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-100.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/montserrat-v25-latin-100.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-300 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('../../assets/fonts/montserrat-v25-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/montserrat-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/montserrat-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/montserrat-v25-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-regular - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-500 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-600 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-800 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('../../assets/fonts/montserrat-v25-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/montserrat-v25-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/montserrat-v25-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/montserrat-v25-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/montserrat-v25-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  /* inter-300 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url('../../assets/fonts/inter-v12-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/inter-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/inter-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/inter-v12-latin-300.svg#Inter') format('svg'); /* Legacy iOS */
  }
  /* inter-regular - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/inter-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/inter-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/inter-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/inter-v12-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
  }
  /* inter-500 - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/fonts/inter-v12-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/inter-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/inter-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/inter-v12-latin-500.svg#Inter') format('svg'); /* Legacy iOS */
  }
  /* inter-600 - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/inter-v12-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/inter-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/inter-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/inter-v12-latin-600.svg#Inter') format('svg'); /* Legacy iOS */
  }
  /* inter-700 - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/inter-v12-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/inter-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/inter-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/inter-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/inter-v12-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
  }